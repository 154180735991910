#page-wrap {
  text-align: center;
  /* Prevent sidebar from showing a scrollbar on page */
  overflow: auto;
}

/* Individual item */
.bm-item {
  display: block;
  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: #000000;
  transition: color 0.2s;
  font-weight: bold;
}

/* Change color on hover */
.bm-item:hover {
  color: white;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #B8FA3E;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #03080c;
}

/* General sidebar styles */
.bm-menu {
  background: #73dddd;
  padding: 2.5em 1.5em 0;
  position: relative;
  font-size: 1.15em;
  left: 0;
  bottom: 38px;
}

/* Styling for menu links */
.bm-menu a {
  color: #000000;
  text-decoration: none;
}

/* Styling for active menu link */
.bm-menu a.active {
  color: white;
  font-weight: bold;
}

/* Styling for menu item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}