.services-container-heb {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  flex-direction: row-reverse; /* Adjust the flex-direction to reverse the order of elements */
}

.solarwork-info-heb {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: row-reverse; /* Adjust the flex-direction to reverse the order of elements */
}

.solarwork-image-heb {
  margin: 0 0 0 50px; /* Adjust margin to position the image on the right */
}

.solarwork-left-heb {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px; /* Adjust margin to position the text on the left */
  margin-right: 0; /* Remove the margin-right */
}

.solarwork-right-heb {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 20px; /* Adjust margin to position the text on the right */
  margin-left: 0; /* Remove the margin-left */
}

.solarwork-panel-heb,
.solarwork-inverter-heb,
.solarwork-home-heb,
.solarwork-meter-heb,
.solarwork-grid-heb,
.solarwork-garden-heb {
  display: flex;
  align-items: center;
  margin-bottom: 70px;
  flex-direction: row-reverse; /* Adjust the flex-direction to reverse the order of elements */
}

.solarwork-panel-img-heb,
.solarwork-inverter-img-heb,
.solarwork-home-img-heb,
.solarwork-meter-img-heb,
.solarwork-grid-img-heb,
.solarwork-garden-img-heb {
  width: 70px;
  height: 70px;
  margin-left: 10px; /* Adjust margin to position the image on the right */
  margin-right: 0; /* Remove the margin-right */
  margin-bottom: 40px;
}

.solarwork-panel-text-heb,
.solarwork-inverter-text-heb,
.solarwork-home-text-heb,
.solarwork-meter-text-heb,
.solarwork-grid-text-heb,
.solarwork-garden-text-heb {
  display: flex;
  flex-direction: column;
  align-items: flex-end; /* Adjust alignment to right-align the text */
  margin-top: 20px;
  margin-bottom: 10px;
}

.solarwork-panel-text-heb,
.solarwork-inverter-text-heb,
.solarwork-home-text-heb,
.solarwork-meter-text-heb,
.solarwork-grid-text-heb,
.solarwork-garden-text-heb {
  display: flex;
}

.solarwork-left-heb,
.solarwork-right-heb {
  max-width: 430px;
}

.solarwork-panel-text-heb p,
.solarwork-inverter-text-heb p,
.solarwork-home-text-heb p,
.solarwork-meter-text-heb p,
.solarwork-grid-text-heb p,
.solarwork-garden-text-heb p {
  max-width: 340px;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #030303;
  text-align: right; /* Adjust text alignment to right-align the text */
}

.solarwork-panel-title-heb,
.solarwork-inverter-title-heb,
.solarwork-home-title-heb,
.solarwork-meter-title-heb,
.solarwork-grid-title-heb,
.solarwork-garden-title-heb {
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Align the title to the right */
  position: relative;
  bottom: 15px;
  font-family: 'Montserrat', sans-serif;
  font-size: 28px;
  font-weight: bold;
  color: #000000;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 20px;
  text-align: right;
  text-decoration: underline;
  text-decoration-color: #ffffff;
}
.solarwork-panel-title-heb::before,
.solarwork-inverter-title-heb::before,
.solarwork-home-title-heb::before,
.solarwork-meter-title-heb::before,
.solarwork-grid-title-heb::before,
.solarwork-garden-title-heb::before {
  content: "";
  flex-grow: 1;
}
/* Reverse the media queries as well */

@media all and (max-width: 355px) {
  /* Adjust styles for smaller screens */
}

@media only screen and (max-width: 767px) {
  .services-container-heb {
    height: auto;
    flex-wrap: wrap;
    padding: 20px;
  }

  .solarwork-info-heb {
    flex-direction: column;
    align-items: center;
  }

  .solarwork-left-heb,
  .solarwork-right-heb {
    margin: 0;
    max-width: 100%;
  }

  .solarwork-image-heb {
    margin: 20px 0;
  }

  .solarwork-panel-heb,
  .solarwork-inverter-heb,
  .solarwork-home-heb,
  .solarwork-meter-heb,
  .solarwork-grid-heb,
  .solarwork-garden-heb {
    margin-bottom: 40px;
  }

  .solarwork-panel-text-heb p,
  .solarwork-inverter-text-heb p,
  .solarwork-home-text-heb p,
  .solarwork-meter-text-heb p,
  .solarwork-grid-text-heb p,
  .solarwork-garden-text-heb p {
    max-width: 90%;
  }

  .solarwork-panel-title-heb,
  .solarwork-inverter-title-heb,
  .solarwork-home-title-heb,
  .solarwork-meter-title-heb,
  .solarwork-grid-title-heb,
  .solarwork-garden-title-heb {
    text-align: center;
  }

  .solarwork-panel-img-heb,
  .solarwork-inverter-img-heb,
  .solarwork-home-img-heb,
  .solarwork-meter-img-heb,
  .solarwork-grid-img-heb,
  .solarwork-garden-img-heb {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .solarwork-image-heb {
    width: 100%;
  }
}

@media only screen and (max-width: 1024px) {
  .services-container-heb {
    height: auto;
    flex-wrap: wrap;
    padding: 20px;
  }

  .solarwork-info-heb {
    flex-direction: column;
    align-items: center;
  }

  .solarwork-left-heb,
  .solarwork-right-heb {
    margin: 0;
    max-width: 100%;
  }

  .solarwork-image-heb {
    margin: 20px 0;
  }

  .solarwork-panel-heb,
  .solarwork-inverter-heb,
  .solarwork-home-heb,
  .solarwork-meter-heb,
  .solarwork-grid-heb,
  .solarwork-garden-heb {
    margin-bottom: 40px;
  }

  .solarwork-panel-text-heb p,
  .solarwork-inverter-text-heb p,
  .solarwork-home-text-heb p,
  .solarwork-meter-text-heb p,
  .solarwork-grid-text-heb p,
  .solarwork-garden-text-heb p {
    max-width: 80%;
  }

  .solarwork-panel-title-heb,
  .solarwork-inverter-title-heb,
  .solarwork-home-title-heb,
  .solarwork-meter-title-heb,
  .solarwork-grid-title-heb,
  .solarwork-garden-title-heb {
    text-align: center;
  }

  .solarwork-panel-img-heb,
  .solarwork-inverter-img-heb,
  .solarwork-home-img-heb,
  .solarwork-meter-img-heb,
  .solarwork-grid-img-heb,
  .solarwork-garden-img-heb {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .solarwork-image-heb {
    width: 80%;
  }
}

@media all and (max-width: 1620px) {
  .solarwork-left-heb,
  .solarwork-right-heb {
    max-width: 300px;
  }

  .solarwork-panel-img-heb,
  .solarwork-inverter-img-heb,
  .solarwork-home-img-heb {
    width: 50px;
    height: 50px;
    margin-right: 5px;
    margin-bottom: 30px;
  }
}