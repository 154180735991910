html {
    scroll-behavior: smooth;
  }
  
  .navbar {
    position: sticky;
    display: flex;
    align-items: center;
    top: 0;
    background-color: rgba(115, 221, 221, 0.233);
    justify-content: space-between;
    height: 80px;
    padding: 0 20px;
    background-image: linear-gradient(to right, #5BE5D5, #B8FA3E);
    background-repeat: no-repeat;
    background-size: 100% 3px;
    background-position: center bottom;
  }
  .navbar.active{
    background-color: rgb(255, 255, 255);
  
    
  }
  
  
  
  /* ssss */
  
  .nav_menu li {
    margin-right: 0px;
    position: relative;
  }
  
  .nav_menu li::after {
    content: "/";
    margin-left: 20px;
    color: #808080;
  }
  
  .nav_menu li:last-child::after {
    content: none;
  }
  
  
  .nav_menu {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  
  
  
  
  .navbar.active .nav_menu li a {
    color: rgb(0, 0, 0);
  }
  
  .nav_menu li{
    position: relative;
    right: 730px;
  }
  
  .nav_menu li::before{
    content: "";
    width: 50%;
    height: 5px;
    background: #B8FA3E;
    position: absolute;
    bottom: -25%;
    left: 15%;
    transform: scaleX(0);
    transition: transform .5s ease;
  } 
  .nav_menu li:hover::before, .nav_menu li.active::before{
    transform: scaleX(1);
  }
  .nav_menu li a {
    text-decoration: none;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: color .3s ease;
  }
  .nav_menu li a:hover {
    color: #B8FA3E;
  }
  
  
  
  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .brand-container {
    flex: 1;
  }
  .nav-menu-container {
    flex: 2;
  }

  .navbar-brand {
    font-weight: bold;
    color: #fff;
    text-decoration: none;

    width: 55%;
    
  }
  
  .nav_menu {
    position: relative;
    left: 555px;
    list-style: none;
    display: flex;
    margin-right: 0;
    padding: 0;
  }
  
  
  
  .nav_link {
    font-size: 16px;
    text-decoration: none;
    margin: 0 20px;
  }
  
  .nav_link:hover {
    color: #000;
  }
  
  .phone-container {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 105px;
    
  }
  
  .phone_link {
    text-decoration: none;
    color: white;
    font-weight: bold;
    margin-right: 15px;
    transition: color .3s ease;
  }


  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .globe-language {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    z-index: 1;
    top: 30px;
    right: 0;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  }
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  .phone_link:hover {
    color: #B8FA3E;
  }
  
  .phone_logo {
    width: 30px;
    height: 30px;
  }
  
  .navbar.active .phone_link{
    color: rgb(0, 0, 0);
  }
  
  
  
                                                                                                                                                                                                                                 
  
  
  .phone_link:hover {
    color: #B8FA3E;
  }
  
  
  
  
  
  
  
  
  
  .home-section, .about-section, .services-section, .contact-section, .calc-section, .xp-section{
    
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; /* add this */
  }
  
  .home-section {
    background-color: #73dddd;
  }
  
  .about-section {
    background: linear-gradient(to bottom right, rgba(200, 200, 200, 0.5), rgba(255, 255, 255, 0)), linear-gradient(to top left, rgba(200, 200, 200, 0.5), rgba(255, 255, 255, 0))
  }
  
  .contact-section{
    background: linear-gradient(to bottom right, rgba(200, 200, 200, 0.5), rgba(255, 255, 255, 0)), linear-gradient(to top left, rgba(200, 200, 200, 0.5), rgba(255, 255, 255, 0))
  }
  .services-section {
    background-color:  #73dddd;
  }
  
  .calc-section{
    background-color: #B8FA3E;
    
  }
  
  
  
  .foot-section{
    height: 50vh;
  
    background-color:  #73dddd;
  }
  
  
  
  
  

  
  #home-main-image {
    max-width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1; /* add this */
  }
  
  .navbar {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 999; /* add this */
  }
  

  #sidebarid {
    display: none;
  
  }
  @media all and (max-width:1620px){
    .navbar {
     
      background-color: rgba(115, 221, 138, 0.233);
     
    }
    .phone_logo{
      display: block;
    }
    .navbar .nav_menu li a {
      display: none;
    }
    .navbar.active .nav_menu li a {
      display: none;
    }
    .nav_icon{
      display: none;
    }
    .nav_menu li::after {
     display: none;
    }


    .home-section, .about-section, .services-section, .contact-section, .calc-section, .xp-section, .foot-section{
    
      height: auto;

    }

    @media all and (max-width: 1024px) {
      .home-section, .about-section, .services-section, .contact-section, .calc-section, .xp-section, .foot-section{
    
        height: auto;

      }

      
      .hamburger-menu {
        display: block;
      }
      .phone-container {
        margin-right: 105px;
      }


      #sidebarid {
        display: block; /* Show the element when the screen is smaller than 1024 pixels */
      }
      .navbar-brand {
        width: 110%;
        height: 250%;
       }


    }
    @media all and (max-width: 867px) {
      .navbar-brand {
        width: 120%;
      }


    }
    

    @media all and (max-width: 767px) {
    



      .home-section, .about-section, .services-section, .contact-section, .calc-section, .xp-section, .foot-section{
    
        height: auto;

      }

      
      .navbar-brand {
        width: 155%;
      }


      .phone-container {
        margin-right: 95px;
      }

      .nav_menu {
        display: none;
      }


    
      .hamburger-menu {
        display: block;
      }


      .dropdown-content {
        position: relative;
        top: 0;
        right: 0;
        transform: none;
        box-shadow: none;
      }

      .globe-language {
      }
      
    }
    @media all and (max-width:455px){
      
      .navbar-brand {
        width: 200%;
        height: 250%;
       }

    }

    @media all and (max-width:415px){
      
      .navbar-brand {
        width: 240%;
        height: 250%;
       }

    }

    @media all and (max-width:385px){
      
      .navbar-brand {
        width: 300%;
        height: 250%;
       }

    }
    

    @media all and (max-width:355px){
      
      .navbar-brand {
        width: 300%;
        height: 250%;
       }

    }

  }