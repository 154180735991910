.services-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;

}

.solarwork-info {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: row;
}

.solarwork-image {
  margin: 0 50px 0 0;
}

.solarwork-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 20px;
}

.solarwork-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
}

.solarwork-panel,
.solarwork-inverter,
.solarwork-home,
.solarwork-meter,
.solarwork-grid,
.solarwork-garden {
  display: flex;
  align-items: center;
   margin-bottom: 30px
}

.solarwork-panel-img,
.solarwork-inverter-img,
.solarwork-home-img,
.solarwork-meter-img,
.solarwork-grid-img,
.solarwork-garden-img {
  width: 70px;
  height: 70px;
  margin-right: 10px;
  margin-bottom: 40px;

}

.solarwork-panel-text,
.solarwork-inverter-text,
.solarwork-home-text,
.solarwork-meter-text,
.solarwork-grid-text,
.solarwork-garden-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
  margin-bottom: 10px;
}

.solarwork-panel-text,
.solarwork-inverter-text,
.solarwork-home-text,
.solarwork-meter-text,
.solarwork-grid-text,
.solarwork-garden-text {
  display: flex;
}

.solarwork-left,
.solarwork-right {
  max-width: 430px;
}


.solarwork-panel-text p,
.solarwork-inverter-text p,
.solarwork-home-text p,
.solarwork-meter-text p,
.solarwork-grid-text p,
.solarwork-garden-text p {
  max-width: 340px;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #030303;

}


.solarwork-panel-title, 
.solarwork-inverter-title, 
.solarwork-home-title, 
.solarwork-meter-title,
.solarwork-grid-title,
.solarwork-garden-title {
  position: relative;
  bottom: 15px;
  font-family: 'Montserrat', sans-serif;
  font-size: 28px;
  font-weight: bold;
  color: #000000;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 20px;
  text-align: center;
  text-decoration: underline;
  text-decoration-color: #ffffff;

}
.intro-text {
  text-align: center;
  margin-bottom: 40px;
  position: absolute;
  top: 80px;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 80%;
}

.intro-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.intro-description {
  font-size: 16px;
  color: #555555;
  margin-bottom: 10px;
}


@media all and (max-width:1620px){
  .solarwork-left,
  .solarwork-right {
    max-width: 300px;
  }

  .solarwork-panel-img,
  .solarwork-inverter-img,
  .solarwork-home-img {
    width: 50px;
    height: 50px;
    margin-right: 5px;
    margin-bottom: 30px;
  }
}


@media only screen and (max-width: 1024px) {
  .services-container {
    height: auto;
    flex-wrap: wrap;
    padding: 20px;
  }
  .intro-text {
    position: static;
    transform: none;
    text-align: center;
    margin-bottom: 20px;
    max-width: 100%;
  }
  
  .solarwork-info {
    flex-direction: column;
    align-items: center;
  }
  
  .solarwork-left, .solarwork-right {
    margin: 0;
    max-width: 100%;
  }
  
  .solarwork-image {
    margin: 20px 0;
  }
  
  .solarwork-panel, 
  .solarwork-inverter, 
  .solarwork-home, 
  .solarwork-meter, 
  .solarwork-grid, 
  .solarwork-garden {
    margin-bottom: 40px;
  }
  
  .solarwork-panel-text p, 
  .solarwork-inverter-text p, 
  .solarwork-home-text p, 
  .solarwork-meter-text p, 
  .solarwork-grid-text p, 
  .solarwork-garden-text p {
    max-width: 80%;
  }
  
  .solarwork-panel-title, 
  .solarwork-inverter-title, 
  .solarwork-home-title, 
  .solarwork-meter-title, 
  .solarwork-grid-title, 
  .solarwork-garden-title {
    text-align: center;
    
  }
  
  .solarwork-panel-img, 
  .solarwork-inverter-img, 
  .solarwork-home-img, 
  .solarwork-meter-img, 
  .solarwork-grid-img, 
  .solarwork-garden-img {
    margin-right: 0;
    margin-bottom: 20px;
  }


  .solarwork-image {
    width: 80%;
  }
}


@media only screen and (max-width: 767px) {
  .services-container {
    height: auto;
    flex-wrap: wrap;
    padding: 20px;
  }
  .intro-text {
    position: static;
    transform: none;
    text-align: center;
    margin-bottom: 20px;
    max-width: 100%;
  }
  
  .solarwork-info {
    flex-direction: column;
    align-items: center;
  }
  
  .solarwork-left, .solarwork-right {
    margin: 0;
    max-width: 100%;
  }
  
  .solarwork-image {
    margin: 20px 0;
  }
  
  .solarwork-panel, 
  .solarwork-inverter, 
  .solarwork-home, 
  .solarwork-meter, 
  .solarwork-grid, 
  .solarwork-garden {
    margin-bottom: 40px;
  }
  
  .solarwork-panel-text p, 
  .solarwork-inverter-text p, 
  .solarwork-home-text p, 
  .solarwork-meter-text p, 
  .solarwork-grid-text p, 
  .solarwork-garden-text p {
    max-width: 90%;
  }
  
  .solarwork-panel-title, 
  .solarwork-inverter-title, 
  .solarwork-home-title, 
  .solarwork-meter-title, 
  .solarwork-grid-title, 
  .solarwork-garden-title {
    text-align: center;

  }
  
  .solarwork-panel-img, 
  .solarwork-inverter-img, 
  .solarwork-home-img, 
  .solarwork-meter-img, 
  .solarwork-grid-img, 
  .solarwork-garden-img {
    margin-right: 0;
    margin-bottom: 20px;
  }


  .solarwork-image {
    width: 100%;
  }
}

@media all and (max-width:355px){


}
