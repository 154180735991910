.bout-div {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-left: 100px;
  
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


.bout-div .solarity-us {
  position: relative;
  width: 50%;
  top: 65px;
  right: 100px;
}
.solarity-us {
  font-size: 18px;
  line-height: 1.5;
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
  padding: 50px 20px;

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: 30vh;
  opacity: 0;
  animation: fadeIn 1s forwards;

}

.solarity-us::before,
.solarity-us::after {
  content: "";
  display: block;
  width: 100px;
  height: 2px;
  background-color:#73dddd;
  margin: 20px auto;
}

.solarity-us::before {
  margin-bottom: 30px;
}

.solarity-us strong {
  font-weight: bold;
  color: #B8FA3E;
}

.bout-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 50px;
  padding-right: 50px;

  top: 50px;
}

.bout-flex-container {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  right: 95px;
}




.bout-solar-profit, .bout-solar-icon, .future-profit-icon {
  width: 70px;
  height: 80px;
  margin-right: 21px;
  opacity: 0;
  animation: fadeIn 1s forwards;
}

#about-solar-title,
#profit-solar-title,
#future-of-title
{
  position: relative;
  font-family: 'Montserrat', sans-serif;
  font-size: 28px;
  font-weight: bold;
  color: #000000;
  text-transform: uppercase;
  letter-spacing: 2px;

  text-decoration: underline;
  text-decoration-color: #B8FA3E;
}

#about-solar-text,
#profit-solar-text,
#future-of-text
{
  font-size: 18px;
  margin-top: 10px;
}



.bout-image-border-div {
  position: relative;
  width: 40vw;
  height: 250px;
  margin-left: -90px;
  border: 5px solid #B8FA3E;

  bottom: 40px;
}

.bout-worker-image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translate(20px, 20px);
  transition: transform 0.5s ease-in-out;

  opacity: 0;
  animation: fadeIn 1s forwards;
}




@media all and (max-width:1620px){
.bout-div {
    display: flex;
    flex-direction: column;
    margin-left: 0;
    padding: 20px 0;


    
  }

  .bout-div .solarity-us {
    position: relative;
    width: 90%;
    top: 0;
    right: 0;
  }

  .bout-container {
    position: relative;
    padding: 20px 0;
    top: 0;
    left: 0;

  }

  .bout-flex-container {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    right: 0;
  }

  #about-solar-title,
  #profit-solar-title,
  #future-of-title {
    font-size: 24px;
  }

  .bout-solar-profit,
  .bout-solar-icon,
  .future-profit-icon {
    width: 50px;
    height: 60px;
    margin-right: 10px;
  }

  .bout-image-border-div {
    margin-left: 0;
    height: 110px;
    width: 70%;

    bottom: 10px;



  }

  .bout-worker-image {
    height: 110px;

  }


}

@media all and (max-width:1024px){

  
  
  }
  




@media (max-width: 767px) {



  .bout-div {
    display: flex;
    flex-direction: column;
    margin-left: 0;
    padding: 20px 0;


    
  }

  .bout-div .solarity-us {
    position: relative;
    width: 90%;
    top: 0;
    right: 0;
  }

  .bout-container {
    position: relative;
    padding: 20px 0;
    top: 0;
    left: 0;
  }

  .bout-flex-container {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    right: 0;
  }

  #about-solar-title,
  #profit-solar-title,
  #future-of-title {
    font-size: 24px;
  }

  .bout-solar-profit,
  .bout-solar-icon,
  .future-profit-icon {
    width: 50px;
    height: 60px;
    margin-right: 10px;
  }

  .bout-image-border-div {
    margin-left: 0;
    height: 110px;
    width: 70%;

    bottom: 10px;



  }

  .bout-worker-image {
    height: 110px;

  }

 

 

}


@media all and (max-width:355px){

 
}