.exp-container {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  background-color: #73dddd;
  
  
}


/* .XP-background::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
} */ 

.exp-container > * {
  position: relative;
  z-index: 1;
  margin: 0;
  padding: 0;
}

.XP-content {
  text-align: center;
}

.XP-heading {
  font-size: 2em;
  margin-bottom: 20px;
  color: white;

}

.XP-text {
  color: #B8FA3E;
  text-align: center;
  font-size: 2em;

}


.XP-text-color {
  color: #73dddd;
  
}