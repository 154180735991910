.bout-div-heb {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-left: 100px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.bout-div-heb .solarity-us-heb {
  position: relative;
 

}

.solarity-us-heb {
  font-size: 18px;
  line-height: 1.5;
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
  padding: 50px 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: 30vh;
  opacity: 0;
  animation: fadeIn 1s forwards;
}

.solarity-us-heb::before,
.solarity-us-heb::after {
  content: "";
  display: block;
  width: 100px;
  height: 2px;
  background-color: #B8FA3E;
  margin: 20px auto;
}

.solarity-us-heb::before {
  margin-bottom: 30px;
}

.solarity-us-heb strong {
  font-weight: bold;
  color: #B8FA3E;
}

.bout-container-heb {}

.bout-flex-container-heb {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: flex-end;
}

.bout-solar-profit-heb,
.bout-solar-icon-heb,
.future-profit-icon-heb {
  width: 70px;
  height: 80px;
  opacity: 0;
  animation: fadeIn 1s forwards;
}



#about-solar-title-heb,
#profit-solar-title-heb,
#future-of-title-heb {
  position: absolute;
  top: 0;
  left: 30%;
  transform: translateX(-50%);
  font-family: 'Montserrat', sans-serif;
  font-size: 28px;
  font-weight: bold;
  color: #000000;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: right;
  width: 100%;
  text-decoration: underline;
  text-decoration-color: #B8FA3E;
  margin-top: 20px; /* Adjust the value as needed */
}

#about-solar-text-heb,
#profit-solar-text-heb,
#future-of-text-heb {
  text-align: right;
  font-size: 18px;
  margin-top: 10px;
}

.bout-image-border-div {
  position: relative;
  width: 40vw;
  height: 250px;
  margin-left: -90px;
  border: 5px solid #B8FA3E;
  bottom: 40px;
}

.bout-worker-image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translate(20px, 20px);
  transition: transform 0.5s ease-in-out;
  opacity: 0;
  animation: fadeIn 1s forwards;
}

@media all and (max-width: 1620px) {
  .bout-div-heb {
    display: flex;
    flex-direction: column;
    margin-left: 0;
    padding: 20px 0;
  }

  .bout-div-heb .solarity-us-heb {
    position: relative;
    width: 90%;
    top: 0;
    right: 0;
  }

  .bout-container-heb {
    position: relative;
    padding: 20px 0;
    top: 0;
    left: 0;
  }

  .bout-flex-container-heb {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    right: 0;
  }

  #about-solar-title-heb,
  #profit-solar-title-heb,
  #future-of-title-heb {
    font-size: 24px;
  }

  .bout-solar-profit-heb,
  .bout-solar-icon-heb,
  .future-profit-icon-heb {
    width: 50px;
    height: 60px;
    margin-right: 10px;
  }

  .bout-image-border-div-heb {
    margin-left: 0;
    height: 110px;
    width: 70%;
    bottom: 10px;
  }

  .bout-worker-image {
    height: 110px;
  }
}

@media all and (max-width: 1024px) {
  /* Add your styles for max-width: 1024px here */
}

@media (max-width: 767px) {
  .bout-div-heb {
    display: flex;
    flex-direction: column;
    margin-left: 0;
    padding: 20px 0;
  }
  
  #future-of-title-heb {
    margin-left: 65px;
    margin-top: -5px;
    /* Existing styles */
    max-width: 70%; /* Adjust the value as needed */
  }
  .bout-div-heb .solarity-us-heb {
    position: relative;
    width: 90%;
    top: 0;
    right: 0;
  }

  .bout-container-heb {
    position: relative;
    padding: 20px 0;
    top: 0;
    left: 0;
  }

  .bout-flex-container-heb {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    right: 0;
  }

  #about-solar-title-heb,
  #profit-solar-title-heb,
  #future-of-title-heb {
    font-size: 24px;
  }


  #future-of-title-heb{
  }
  .bout-solar-profit-heb,
  .bout-solar-icon-heb,
  .future-profit-icon-heb {
    width: 50px;
    height: 60px;
    margin-right: 10px;
  }

  .bout-image-border-div {
    margin-left: 0;
    height: 110px;
    width: 70%;
    bottom: 10px;
  }

  .bout-worker-image {
    height: 110px;
  }
}

@media all and (max-width: 355px) {
  /* Add your styles for max-width: 355px here */
}