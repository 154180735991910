.exp-container {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  background-color: #73dddd;
}

.XP-background {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
}



.XP-background-heb img {
  object-fit: cover;
  object-position: center;
  height: 130%;
  width: 100%;
}

.exp-container > * {
  position: relative;
  z-index: 1;
  margin: 0;
  padding: 0;
}

.XP-content {
  text-align: center;
  position: relative;
  top: 155px;
}

.XP-heading {
  font-size: 2em;
  margin-bottom: 20px;
  color: white;
}

.XP-text {
  color: #B8FA3E;
  text-align: center;
  font-size: 2em;
}

.XP-text-color {
  color: #73dddd;
}