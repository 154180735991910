.privacy-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .privacy-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .privacy-text {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  h2 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  p {
    font-size: 16px;
    margin-bottom: 20px;
  }