.Mainpage-heb {
  position: relative;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
  margin: 0;
  padding: 0;

  width: 100%;
}

.gif-wrapper-heb {
  position: relative;
}

.home-main-video-heb {
  width: 100%;
  height: 100vh;

}

.home-main-video-heb.loaded {
  opacity: 1;
}

.lmao-div {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 2rem;
}

.main-btn {
  position: absolute;
  border-radius: 20px;
  border: none;
  background: linear-gradient(to right, #5BE5D5, #B8FA3E);
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  position: absolute;
  left: 50%;
  top: 10px;
  transform: translateX(-50%);
}

.contact-main {
  position: absolute;
  z-index: 1;
  bottom: 0px;
  background-color: #000000b7;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.contact-title {
  font-family: "Arial", sans-serif;
  font-size: 24px;
  font-weight: bold;
  color: white;
  text-align: center;
  margin-bottom: 20px;
}

.contact-main form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.contact-main form .MuiTextField-root {
  width: 300px;
  height: 50px;
  background-color: #fff;
  border-radius: 5px;
}

.contact-submit {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
}

.contact-submit:hover {
  background-color: #0062cc;
}

@media all and (max-width: 1620px) {
  .contact-main {
    height: auto;
    padding: 20px 0;
  }

  .contact-main form {
    flex-direction: column;
  }

  .main-btn {
    position: relative;
    bottom: 2px;
  }
}

@media all and (max-width: 1024px) {
  .main-btn {
    position: relative;
    bottom: 25px;
  }
}

@media all and (max-width: 767px) {
  .main-btn {
    position: relative;
    bottom: 25px;
  }

  .home-main-video-heb {
    width: 100%;
   
  }
  
}

@media all and (max-width: 355px) {
  /* Adjust styles as needed */
}
