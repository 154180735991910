.calc-second {
  display: flex;
  flex-direction: column;
  position: relative;
  bottom: 20px;
  height: 200vh;
}

.page-content {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: none;
}

.image-container {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  box-sizing: border-box;
  color: #fff;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-family: Roboto, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  margin: 0;
  outline-color: #fff;
  outline-style: none;
  outline-width: 0;
  width: 100%;
  height: 95vh; /* Adjust the height as desired */
}

#calc-intro-text{
  padding-left: 50px;
  padding-right: 50px;
  width: 50vw;
}


.main-solar-image {
  width: 100%;
  object-fit: cover;
}

.calc-text {
  margin-top: 20px;
  text-align: center;
}

.ROI-calc {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.ROI-calc .MuiGrid-item {
  width: 100%;
}

@media (max-width: 768px) {
  .ROI-calc .MuiBox-root {
    width: 100%;
  }

  #calc-intro-text{
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
  }
}

@media (max-width: 480px) {
  .ROI-calc {
    margin-top: 10px;
  }
}