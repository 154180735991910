.footer-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 20px 0;
    
  }
  
  .rectangle {
    position: relative;
    width: 100%;
    height: 400px;
    background-color: #B8FA3E;
    box-sizing: border-box;
    display: flex;
    bottom: 350px;
    z-index: 1;
  }
  
  .image-section {
    width: 50%;
    height: 100%;
    padding: 20px 20px 20px 0; /* right padding is 0, left padding is 20px */
    box-sizing: border-box; /* include padding in width */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image-section img {
    width: 100%;
    height: 111%;
    object-fit: cover;
  }
  
  .text-section-heb {
    width: 45%;
    height: 100%;
    padding: 20px 0 20px 20px; /* left padding is 0, right padding is 20px */
    box-sizing: border-box; /* include padding in width */
    text-align: right;
  }
  
  .text-section-heb h2 {
    font-size: 24px;
    margin-top: 0;
  }
  
  .text-section-heb p {
    font-size: 16px;
    line-height: 1.5;
  }
  
  .office-info-grid {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    bottom: 200px; /* adjusted value */
  }
  
  .office-info-grid-item {
    display: inline-block;
    margin: 10px; /* adjusted value */
  }
  
  .office-info-grid-item:not(:last-child) {
    margin-right: 20px;
  }
  
  .office-info-grid-item-content {
    display: flex;
    align-items: center;
  }
  
  .office-info-grid h4 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0px;
  }
  
  .office-info-grid p {
    font-size: 14px;
    margin-bottom: 0;
    line-height: 1.5;
  }
  
  .office-info-grid-item img {
    width: 50px;
    height: 50px;
    margin-right: 15px;
  }
  
  .office-info-grid-item-text {
    display: flex;
    flex-direction: column;
  }


  @media all and (max-width:767px){
    .rectangle {
      position: relative;
      width: 100%;
      height: 490px;
     
      box-sizing: border-box;
      display: flex;
      bottom: 350px;
      z-index: 1;
    }

    .image-section img {
      width: 100%;
      height: 111%;
      object-fit: cover;
    }
    

  }