.footer {
    
    background-color: #333;
    color: #fff;
    padding: 30px 0;
  }
  
.footer-container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  
  
  
  
  
  
  
.footer-menu {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
  }
  
.footer-menu li {
    margin-right: 20px;
  }
  
.footer-menu li:last-child {
    margin-right: 0;
  }
  
.footer-menu li a {
    color: #fff;
    text-decoration: none;
  }
  
.footer-menu li a:hover {
    text-decoration: underline;
  }
  