.phone-container2 {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 9999;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .phone-container2.show {
    opacity: 1;
  }
  
  .phone-link2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    background-color: #333;
    border-radius: 50%;
    color: #fff;
    text-decoration: none;
  }
  
  .phone-icon2 {
    font-size: 24px;
  }