.calc-second {
  display: flex;
  flex-direction: column;
  position: relative;
  bottom: 20px;
  background-color: #f2f2f2;

}

.page-content {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: none;
}

.image-container {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  box-sizing: border-box;
  color: #fff;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-family: Roboto, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  margin: 0;
  outline-color: #fff;
  outline-style: none;
  outline-width: 0;
  width: 100%;
  height: 95vh; /* Adjust the height as desired */
}

.main-solar-image {
  width: 100%;
  object-fit: cover;
  height: 70%;
}

.calc-text {
  position: relative;
  text-align: center;
  bottom: 150px;
}


#calc-title{
  font-size: 40px;
}

#calc-intro-text{
  font-size: 19.2px;
}

.ROI-calc {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #f2f2f2;
  bottom: 65px;
}

.ROI-calc .MuiGrid-item {
  width: 100%;
}

.ROI-calc .MuiSlider-root {
  width: 100%;
}

.roi-display {
  margin-top: 20px;
  text-align: center;
}

.roi-display h6 {
  font-size: 18px;
  font-weight: bold;
  color: #555;
}


.iframe-text{
  position: relative;
  text-align: center;
  font-size: 40px;

}