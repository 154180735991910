.service2-services-icons{
  display: flex;
justify-content: center;
gap: 20px;
flex-wrap: wrap;
padding: 20px 0;

}




.service2-text {
  text-align: center;
  margin: 50px 0;
}

.service2-text h2 {
  font-size: 32px;
  margin-bottom: 20px;
}

.service2-text p {
  font-size: 18px;
  line-height: 1.5;
  color: #666;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
}

.section-with-cross-overlay-heb {
  position: relative;
}

.section-with-cross-overlay-heb::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1000%;
  background-image: url("../../../components/assets/cross-grid.png");
  background-repeat: repeat;
  z-index: -1;
}
