
.calc-container{
  padding: 20px 0;

}

.calc-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    padding-left: 25px;
    padding-right: 25px;

  }
  
  .step-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
    align-self: flex-start; /* add this line */

  }
  
  .step-image-container {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    box-shadow: 0 0 0 3px #252525 inset;
    transition: all 280ms ease;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .step-image {
    max-width: 60%;
    max-height: 100%;
    object-fit: cover;
  }
  
  .step-image-container:hover {
    transform: scale(1.05);
    box-shadow: 0 0 0 3px #ebebeb inset;
  }

  .calc-heading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .calc-subheading {
    font-size: 20px;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .calc-button {
    background-color: #5BE5D5;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-top: 10px;
    cursor: pointer;
    border-radius: 5px;
    display: block;
    margin: 0 auto;
    width: 20%;
    
  }
  
  .calc-button:hover {
    background-color: #44ada1;
  }

  .step-line {
    height: 2px;
    width: 100%;
    background-color: black;
    margin-bottom: 40px;
  }
  
  @media only screen and (max-width: 768px) {
    .calc-main {
      flex-wrap: wrap;
    }
  
    .step-item {
      width: 100%;
      text-align: center;
      align-self: auto; /* reset align-self */

      
    }
  
    .step-line {
      display: none;
    }
  }